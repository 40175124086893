import MainButtons from 'pages/filial-page/main-buttons/MainButtons';
import {useParams} from 'react-router-dom';
import {configs} from 'shared/config/config';
import Logo from 'components/logo/Logo';
import css from './FilialPage.module.css'

const FilialPage = () => {
    const {id} = useParams();
    const filialConfig = configs.find(x => x.id.toString() === id);

    if (!filialConfig) return null;

    return (
        <main className={css.content}>
            <Logo/>
            <span className={css.title}>Вам понравилось обслуживание?</span>
            <MainButtons config={filialConfig}/>
        </main>
    );
};

export default FilialPage;