import React, {ReactNode} from 'react';
import classNames from 'classnames';
import css from './Button.module.css';

export interface ButtonProps {
    className?: string;
    onClick?: () => void;
    color: 'primary' | 'secondary';
    children: ReactNode;
    disabled?: boolean;
}

const Button = (props: ButtonProps) => {

    const {className, children, onClick, color, disabled} = props;

    return (
        <button type='button' onClick={onClick} disabled={disabled}
                className={classNames(
                    css.button,
                    className,
                    color === 'primary' && css.primary,
                    color === 'secondary' && css.secondary
                )}
        >
            {children}
        </button>
    );
};

export default Button;
