import {ReactComponent} from '*.svg';
import css from './ButtonContent.module.css'

interface ButtonContentProps {
    Icon: typeof ReactComponent;
    text: string;
}

const ButtonContent = ({Icon, text}: ButtonContentProps) => {
    return (
        <div className={css.content}>
            <div className={css.iconWrapper}>
                <Icon />
            </div>
            {text}
        </div>
    );
};

export default ButtonContent;