import css from 'components/text-area/TextArea.module.css';
import {FormEvent} from 'react';

interface InputProps {
    value: string;
    onChange: (value: string) => void;
    label: string;
}

const TextArea = (props: InputProps) => {
    
    const {value, onChange, label} = props;
    
    return (
        <div className={css.container}>
            <label className={css.label}>{label}</label>
            <textarea className={css.input}
                      value={value}
                      onChange={e => onChange(e.currentTarget.value)}
            />
        </div>
    );
};

export default TextArea;