import {ReactNode} from 'react';
import {createPortal} from 'react-dom';

interface PortalProps {
    children?: ReactNode;
    destination?: HTMLElement;
}

const Portal = (props: PortalProps) => {
    const {
        children,
        destination = document.body,
    } = props;

    return createPortal(children, destination);
};

export default Portal;