import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import FilialPage from 'pages/filial-page/FilialPage';
import PageError from 'components/page-error/PageError';
import NotFound from 'components/not-found/NotFound';
import MainPage from 'pages/main-page/MainPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
        errorElement: <PageError />,
    },
    {
        path: "/:id",
        element: <FilialPage />,
        errorElement: <PageError />,
    },
    {
        path: "*",
        element: <NotFound />
    }
]);

const AppRouter = () => (
        <RouterProvider router={router}/>
    );

export default AppRouter;