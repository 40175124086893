import {useState} from 'react';

export const useFetching = (fetchFn: (...args: any[]) => Promise<any>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const fetching = async (...args: any[]) => {
        setIsLoading(true);
        setError(undefined);
        try {
            await fetchFn(...args);
        } catch (e: any) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }

    return {fetching, isLoading, error};
}
