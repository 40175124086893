import ButtonIcon from 'components/button-icon/ButtonIcon';
import {ReactComponent as CloseIcon} from 'shared/icons/close.svg';
import css from './ModalHeader.module.css'

export interface ModalHeaderProps {
    title: string;
    onClose: () => void;
}

const ModalHeader = (props: ModalHeaderProps) => {
    const {title, onClose} = props;

    return (
        <div className={css.header}>
            <span>{title}</span>
            <ButtonIcon Icon={CloseIcon} type='circle' onClick={onClose} />
        </div>
    );
};

export default ModalHeader;