import React from 'react';

const PageError = () => {
    return (
        <div>
            Произошла ошибка :(
        </div>
    );
};

export default PageError;