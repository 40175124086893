import { ReactComponent } from '*.svg';
import classNames from 'classnames';
import css from './ButtonIcon.module.css';

export interface ButtonIconProps {
    className?: string;
    Icon: typeof ReactComponent;
    onClick?: () => void;
    type: 'circle';
}

const ButtonIcon = (props: ButtonIconProps) => {

    const {className, Icon, onClick, type} = props;

    return (
        <div className={classNames(
            css.buttonWrapper,
            className,
            type === 'circle' && css.circle
        )}
             onClick={onClick}
        >
            <button type='button' className={css.button}>
                <Icon />
            </button>
        </div>
    );
};

export default ButtonIcon;