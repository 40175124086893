import {ReactNode} from 'react';
import css from './ModalBody.module.css';

interface ModalBodyProps {
    children: ReactNode;
}

const ModalBody = ({children}: ModalBodyProps) => {
    return (
        <div className={css.body}>
            {children}
        </div>
    );
};

export default ModalBody;