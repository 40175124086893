import {ReactNode} from 'react';
import ModalHeader from 'components/modal/header/ModalHeader';
import ModalBody from 'components/modal/body/ModalBody';
import css from './ModalContent.module.css'

interface ModalContentProps {
    children: ReactNode;
    title: string;
    onClose: () => void;
}

const ModalContent = (props: ModalContentProps) => {

    const {children, title, onClose} = props;

    return (
        <div className={css.content} onClick={(e) => e.stopPropagation()}>
            <ModalHeader title={title} onClose={onClose} />
            <ModalBody>
                {children}
            </ModalBody>
        </div>
    );
};

export default ModalContent;