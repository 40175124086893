import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import ButtonContent from './button-content/ButtonContent';
import {ReactComponent as YandexLogo} from 'shared/icons/yandex.svg';
import {ReactComponent as DoubleGis} from 'shared/icons/2gis.svg';
import {ReactComponent as Google} from 'shared/icons/google.svg';
import {FilialConfig} from 'shared/config/config';
import css from './ReviewGoodModal.module.css';

interface ReviewGoodModalProps {
    isOpen: boolean;
    onClose: () => void;
    config: FilialConfig;
}

const ReviewGoodModal = ({isOpen, onClose, config}: ReviewGoodModalProps) => {

    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose} title='Оставить отзыв'>
            <div className={css.textContent}>
                <p>
                    Рады, что вам все понравилось!
                    Мы ценим ваше мнение и будем благодарны, если вы поделитесь им
                </p>
                <p>
                    Чтобы оставить отзыв, выберите одну из площадок ниже и нажмите на нее
                </p>
            </div>
            <div className={css.buttonsContent}>
                {config.links.yandex && (
                    <Button color='primary' onClick={() => window.open(config.links.yandex, '_blank')}>
                        <ButtonContent Icon={YandexLogo} text='Яндекс карты' />
                    </Button>
                )}
                {config.links.doubleGis && (
                    <Button color='primary' onClick={() => window.open(config.links.doubleGis, '_blank')}>
                        <ButtonContent Icon={DoubleGis} text='2gis' />
                    </Button>
                )}
                {config.links.google && (
                    <Button color='primary' onClick={() => window.open(config.links.google, '_blank')}>
                        <ButtonContent Icon={Google} text='google maps' />
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default ReviewGoodModal;