import {configs} from 'shared/config/config';
import Button from 'components/button/Button';
import {useNavigate} from 'react-router-dom';
import css from './MainPage.module.css';
import Logo from 'components/logo/Logo';

const MainPage = () => {
    const config = configs;
    const navigate = useNavigate();

    return (
        <main className={css.main}>
            <Logo />
            {config.map(x => (
                <Button color='primary' onClick={() => navigate(x.id.toString())}>
                    {x.address}
                </Button>
            ))}
        </main>
    );
};

export default MainPage;