import Modal from 'components/modal/Modal';
import Input from 'components/input/Input';
import {useState} from 'react';
import css from './ReviewBadModal.module.css';
import TextArea from 'components/text-area/TextArea';
import Button from 'components/button/Button';
import {useFetching} from 'hooks/useFetching';

interface ReviewBadModalProps {
    firm: string;
    isOpen: boolean;
    onClose: () => void;
}

interface ReviewMessage {
    firm: string;
    from: string;
    message: string;
}

const sendReviewMessage = (data: ReviewMessage) => {
    return fetch('https://bo.ksao.ru/api/reviews', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
    });
}

const ReviewBadModal = ({isOpen, onClose, firm}: ReviewBadModalProps) => {

    const [contact, setContact] = useState('');
    const [text, setText] = useState('');
    const [result, setResult] = useState('');
    const {fetching, isLoading, error} = useFetching(sendReviewMessage);

    const onClick = async () => {
        const data: ReviewMessage = {
            firm: firm,
            from: contact,
            message: text
        };
        await fetching(data);
        setResult('Ваша претензия успешно отправлена');
        setText('');
        setContact('');
    }

    if (!isOpen) return null;

    const canSend = !!contact && !!text;

    return (
        <Modal isOpen={isOpen} onClose={onClose} title='Написать директору'>
            <p>
                Расскажите, что вам не понравилось и что
                мы можем делать лучше. Директор накажет
                виновных, свяжется с вами и предложит
                решение проблемы.
            </p>
            <div className={css.container}>
                <Input value={contact}
                       onChange={(value) => setContact(value)}
                       label='Оставьте ваш контакт для связи'
                />
                <TextArea value={text}
                          onChange={(value) => setText(value)}
                          label='Расскажите, что вам не понравилось.'
                />
                <Button className={css.submitBtn} color='primary'onClick={onClick} disabled={!canSend}>
                    Отправить
                </Button>
                {result && result}
            </div>
        </Modal>
    );
};

export default ReviewBadModal;
