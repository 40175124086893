import css from './App.module.css';
import AppRouter from 'app/router/AppRouter';

function App() {
  return (
    <div className={css.App}>
        <div className={css.contentWrapper}>
            <AppRouter />
        </div>
    </div>
  );
}

export default App;
