import css from './ModalOverlay.module.css'
import {ReactNode} from 'react';

interface ModalOverlayProps {
    children: ReactNode;
    onClose: () => void;
}

const ModalOverlay = ({children, onClose}: ModalOverlayProps) => {
    return (
        <div className={css.overlay} onClick={onClose}>
            {children}
        </div>
    );
};

export default ModalOverlay;