export interface MapConfig {
  yandex?: string;
  doubleGis?: string;
  google?: string;
}

export interface FilialConfig {
  id: number;
  address: string;
  links: MapConfig;
}

export const configs: FilialConfig[] = [
  {
    id: 1,
    address: 'Красное Село, Гвардейская 9',
    links: {
      yandex: 'https://yandex.ru/maps/org/inzhenernaya_santekhnika_i_elektrika_ksao/1806762162/?ll=30.085297%2C59.736272&z=16',
      doubleGis: 'https://2gis.ru/spb/firm/70000001023537419',
      google: 'https://goo.gl/maps/bWSpUnz9QiipGqGB8'
    },
  },
  {
    id: 2,
    address: 'п. Виллози, Гатчинское шоссе 7а',
    links: {
      yandex: 'https://yandex.ru/maps/org/ksao/130590173501/?ll=30.100659%2C59.697941&z=16',
      doubleGis: 'https://2gis.ru/spb/firm/70000001061526396'
    },
  },
];
