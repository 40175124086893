import {ReactNode} from 'react';
import Portal from 'components/portal/Portal';
import ModalOverlay from 'components/modal/overlay/ModalOverlay';
import css from './Modal.module.css';
import classNames from 'classnames';
import ModalContent from 'components/modal/content/ModalContent';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    title: string;
}

const Modal = (props: ModalProps) => {

    const {children, isOpen, onClose, title} = props;

    return (
        <Portal>
            <div className={classNames(css.modal, isOpen && css.modal__open)}>
                <ModalOverlay onClose={onClose}>
                    <div className={css.contentWrapper}>
                        <ModalContent title={title} onClose={onClose}>
                            {children}
                        </ModalContent>
                    </div>
                </ModalOverlay>
            </div>
        </Portal>
    );
};

export default Modal;