import React from 'react';
import logo from 'shared/images/logo.png'

const Logo = () => {
    return (
        <div>
            <img src={logo} alt='Логотип' width={220}/>
        </div>
    );
};

export default Logo;