import css from './Input.module.css';
import {FormEvent} from 'react';

interface InputProps {
    value: string;
    onChange: (value: string) => void;
    label: string;
}

const Input = (props: InputProps) => {
    
    const {value, onChange, label} = props;
    
    return (
        <div className={css.container}>
            <label className={css.label}>{label}</label>
            <input type='text'
                   className={css.input}
                   value={value}
                   onChange={(e: FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
            />
        </div>
    );
};

export default Input;