import {useState} from 'react';
import Button from 'components/button/Button';
import ReviewGoodModal from 'components/review-good-modal/ReviewGoodModal';
import ReviewBadModal from 'components/review-bad-modal/ReviewBadModal';
import {FilialConfig} from 'shared/config/config';
import css from 'pages/filial-page/main-buttons/MainButtons.module.css';

interface MainButtonsProps {
    config: FilialConfig
}

const MainButtons = ({config}: MainButtonsProps) => {

    const [isReviewGoodOpen, setIsReviewGoodOpen] = useState(false);
    const [isReviewBadOpen, setIsReviewBadOpen] = useState(false);

    return (
        <>
            <div className={css.buttons}>
                <Button color='primary' onClick={() => setIsReviewGoodOpen(true)}>
                    Все отлично!
                </Button>
                <Button color='secondary' onClick={() => setIsReviewBadOpen(true)}>
                    Есть претензия
                </Button>
            </div>
            <ReviewGoodModal
                config={config}
                isOpen={isReviewGoodOpen}
                onClose={() => setIsReviewGoodOpen(false)}
            />
            <ReviewBadModal
                firm={config.address}
                isOpen={isReviewBadOpen}
                onClose={() => setIsReviewBadOpen(false)}
            />
        </>
    );
};

export default MainButtons;
